






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class OverlayLoader extends Vue {
    @Prop({default: 50}) size!: number 
}
